.footer {
	width: 100%;
}

.footer-content {
	font-size: 1.2em;
	color: #818181;
	max-width: 980px;
	padding: 1.5em 0;
	margin: 0 auto;
}

@media (max-width: 768px) {
	.footer-content {
		text-align: center;
		padding: 1em 1.5em;
	}
}