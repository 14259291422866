.motominion-pic-container {
	text-align: center;
	margin-bottom: 6em;
}

.section-scroll {
	margin-bottom: 4.5em;
}

.page-subtitle {
	font-family: 'Poppins', sans-serif;
 	font-size: 2em;
 	color: #353535;
 	margin-bottom: 1.5em;
}

.section-scroll-container {
	margin-top: 6em;
	margin-bottom: 4.5em;
}

@media (min-width: 768px) {
	.motominion-pic {
		height: 500px;
		animation: blur-in 1s ease-out both;
    -webkit-animation: blur-in 1s ease-out both;
	}

	.section-scroll {
		animation: slide-in-transparent-left 1s ease-out both;
	  -webkit-animation: slide-in-transparent-left 1s ease-out both;
	}

	.column-container {
		position: relative;
	}

	.column {
		position: absolute;
	  top: 50%;
	}

	.column-text {
		transform: translateY(-50%);
	}

  .pitch {
		text-align: right;
	}
}

@media (max-width: 768px) {
  .motominion-pic {
		height: 200px;
	}

	.page-subtitle {
		font-size: 1.5em;
	}

	.pitch {
		text-align: center;
	}
}