.menu {
	background-color: #353535;
	width: 100%;
	padding: 3em 6em;
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 3;
}

.menu-close {
  color: white;
  float: right;
  cursor: pointer;
}

.menu-list {
	list-style: none;
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-link {
	font-family: 'Poppins', sans-serif;
 	font-size: 2.5em;
 	line-height: 1.8;
 	color: white;
 	transition: 0.4s;
}

.menu-link:hover {
	text-decoration: none;
	color: #dcae4b;
}

@media (min-width: 768px) {
	.menu-item {
		animation: slide-in-transparent-left 1s ease-out both
	}
}

@media (max-width: 768px) {
	.menu {
		padding: 1em 1.5em;
	}

	.menu-link {
		font-size: 2em;
	}

	.menu-close {
		font-size: 0.9em;
	}
}