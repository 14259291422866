.hello {
	font-family: 'Montserrat', sans-serif;
  font-weight: bold;
	font-size: 11.5em;
	line-height: 1;
	color: #353535;
	display: block;
	position: relative;
  z-index: 2;
}

.underline {
  background-color: #dcae4b;
  height: 40px;
  width: 350px;
  position: relative;
  bottom: 60px;
  left: 60px;
}

.sidebar {
  background-color: #dcae4b;
  height: 60px;
  width: 8px;
  margin-right: 1.5em;
  float: left;
  position: relative;
}

.intro {
  font-size: 1.5em;
  color: #353535;
  position: relative;
}

.intro-secondary {
  font-family: 'Montserrat', sans-serif;
  line-height: 30px;
}

.intro-primary {
  font-family: 'Abril Fatface';
}

.profile-pic-container {
  text-align: center;
}

@media (min-width: 768px) {
  .hello {
    animation: slide-in-invisible-right 1s ease-out both;
    -webkit-animation: slide-in-invisible-right 1s ease-out both;
  }

  .underline {
    animation: slide-in-invisible-left 1s ease-out both;
    -webkit-animation: slide-in-invisible-left 1s ease-out both;
  }

  .sidebar::after {
    content: '';
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: retract-down 0.5s 1s ease-in-out both;
    -webkit-animation: retract-down 0.5s 1s ease-in-out both;
  }

  .intro::after {
    content: '';
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 8px;
    animation: retract-right 1s 1.5s ease-in-out both;
    -webkit-animation: retract-right 1s 1.5s ease-in-out both;
  }

  .profile-pic {
    height: 275px;
    animation: blur-in 0.5s 1s ease-out both;
    -webkit-animation: blur-in 0.5s 1s ease-out both;
  }
}

@media (max-width: 768px) {
  .hello {
    font-size: 7em;
  }

  .underline {
    width: 80%
  }

  .intro {
    font-size: 1.25em;
  }

  .profile-pic {
    height: 175px;
    margin: 0 auto 3em;
  }
}

/* Animations */

/* Hello */
@keyframes slide-in-invisible-right {
  0% {
    opacity: 0;
    transform: translateX(250px);
    -webkit-transform: translateX(250px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-invisible-right {
  0% {
    opacity: 0;
    transform: translateX(250px);
    -webkit-transform: translateX(250px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

/* Underline */
@keyframes slide-in-invisible-left {
  0% {
    opacity: 0;
    transform: translateX(-250px);
    -webkit-transform: translateX(-250px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-invisible-left {
  0% {
    opacity: 0;
    transform: translateX(-250px);
    -webkit-transform: translateX(-250px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

/* Sidebar */
@keyframes retract-down {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

@-webkit-keyframes retract-down {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

/* Intro */
@keyframes retract-right {
  0% {
    left: 8px;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes retract-right {
  0% {
    left: 8px;
  }
  100% {
    left: 100%;
  }
}

/* Profile Pic */
@keyframes blur-in {
  0% {
    opacity: 0;
    filter: blur(40px);
    -webkit-filter: blur(40px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    -webkit-filter: blur(0);
  }
}

@-webkit-keyframes blur-in {
  0% {
    filter: blur(40px);
    -webkit-filter: blur(40px);
  }
  100% {
    filter: blur(0);
    -webkit-filter: blur(0);
  }
}