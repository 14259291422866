.page-number {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 8.5em;
	line-height: 1;
	color: #353535;
	position: relative;
	z-index: 1;
}

.strike-through {
	background-color: #dcae4b;
	height: 8px;
	position: relative;
}

.page-title {
  font-family: 'Abril Fatface';
  font-weight: bold;
  font-size: 4em;
  color: #353535;
  margin: 2em 0 1em;
}

.page-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1em;
  color: #353535;
  margin-bottom: 2.5em;
}

.section {
  margin-bottom: 4.5em;
}

.link {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1em;
  letter-spacing: 5px;
  color: #353535;
}

.link:hover {
  text-decoration: none;
  color: #353535;
}

.arrow {
  color: #dcae4b;
  margin-top: 0.5em;
  display: block;
}

@media (min-width: 768px) {
  .strike-through {
    width: 250px;
    bottom: 4.5em;
    right: 6em;
    animation: expand-left 1s ease-in-out both;
    -webkit-animation: expand-left 1s ease-in-out both;
  }

  .section {
    animation: slide-in-transparent-left 1s ease-out both;
    -webkit-animation: slide-in-transparent-left 1s ease-out both;
  }
}

@media (max-width: 768px) {
  .page-number {
    font-size: 5em;
  }

  .page-title {
    font-size: 2.5em;
    margin-top: 1.5em;
  }

  .strike-through {
    width: 90px;
    bottom: 2.9em;
    right: 1em;
  }
}

/* Animations */

/* Strike-Through */
@keyframes expand-left {
  0% {
    width: 0;
  }
  100% {
    width: 250px;
  }
}

@-webkit-keyframes expand-left {
  0% {
    width: 0;
  }
  100% {
    width: 250px;
  }
}

/* Section */
@keyframes slide-in-transparent-left {
  0% {
    opacity: 0;
    transform: translateX(-200px);
    -webkit-transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-tranparent-left {
  0% {
    opacity: 0;
    transform: translateX(-200px);
    -webkit-transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}