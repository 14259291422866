.navigation-link:hover {
	text-decoration: none;
}

.navigation-number {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 8.5em;
	line-height: 1.2;
	color: #353535;
	transition: 0.4s;
}

.navigation-number:hover {
	color: #dcae4b;
}

.navigation-title {
	font-family: 'Abril Fatface';
	font-weight: bold;
	font-size: 1.15em;
	letter-spacing: 0.4em;
	color: #dcae4b;
}

@media (min-width: 768px) {
  .navigation-number {
  	display: block;
	  animation: slide-in-left 1s ease-out both;
	  -webkit-animation: slide-in-left 1s ease-out both;
	}
}

@media (max-width: 768px) {
  .navigation-number {
  	font-size: 5em;
	}

	.navigation-number {
		display: block;
		margin: 0 0.75em 0.25em 0;
		float: left;
	}

	.navigation-title {
    font-size: 1.5em;
		line-height: 4em;
	}
}

/* Animations */

/* Navigation Number */
@keyframes slide-in-left {
  0% {
  	opacity: 0;
  	transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
  }
  100% {
  	opacity: 1;
  	transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-left {
  0% {
  	opacity: 0;
  	transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
  }
  100% {
  	opacity: 1;
  	transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}