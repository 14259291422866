.navigation-items {
	list-style: none;
	width: 100%;
	padding: 0;
	margin-top: 9em;
}
	
.navigation-items li {
	display: inline-block;
}

.navigation-items li:nth-child(1) .navigation-number {
  animation-delay: 1.5s;
}

.navigation-items li:nth-child(2) .navigation-number {
  animation-delay: 2s;
}

.navigation-items li:nth-child(3) .navigation-number {
  animation-delay: 2.5s;
}

.navigation-items li:nth-child(4) .navigation-number {
  animation-delay: 3s;
}

@media (max-width: 768px) {
  .navigation-items {
  	margin-top: 7em;
  }
}