.App {
	font-size: 12px; 
	background-color: #f8f8f8;
}

.page-content {
	max-width: 980px;
	padding: 15em 0;
	margin: 0 auto;
}

@media (max-width: 768px) {
	.page-content {
		padding: 7em 1.5em;
	}
}