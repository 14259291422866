.header {
	width: 100%;
	padding: 3em 6em;
	display: flex;
	justify-content: space-between;
	align-items: center;
 	z-index: 2;
}

.header-brand {
	font-family: 'Poppins', sans-serif;
 	font-size: 2.5em;
 	text-decoration: none;
 	color: #353535;
 	transition: 0.4s;
}

.header-brand:hover {
	text-decoration: none;
	color: #dcae4b;
}

.header-link, 
.header-hamburger {
	font-size: 1.2em;
	margin-left: 2.5em;
}

.header-link {
	color: #9c9c9c;
}

.header-link:hover {
	color: #9c9c9c;
}

.header-hamburger {
	color: #353535;
	cursor: pointer;
}

@media (min-width: 768px) {
	.header {
		position: fixed;
		top: 0;
	}
}

@media (max-width: 768px) {
	.header {
		padding: 1em 1.5em;
	}

	.header-brand {
		font-size: 2em;
	}

	.header-link,
	.header-hamburger {
		font-size: 1.1em;
	}
}