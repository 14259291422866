.image-grid {
  margin-top: 8.5em;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  animation: blur-in 1s ease-out both;
  -webkit-animation: blur-in 1s ease-out both;
}

.about-image-a {
  height: 130px;
  width: 125px;
  position: absolute;
  top: 0;
  right: 220px;
}

.about-image-b {
  height: 450px;
  width: 250px;
  position: absolute;
  top: 150px;
  right: 220px;
}

.about-image-c {
  height: 300px;
  width: 200px;
  position: absolute;
  top: 0;
  right: 0;
}